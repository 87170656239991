// src/components/WineClub/HomeHero.tsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import JoinWineClubModal from '../WineClub/JoinWineClubModal'; // Adjust the path based on your folder structure
import { ReactComponent as ViaLink } from '../../assets/images/ViaLink.svg';
import { motion } from 'framer-motion';

const HomeHero: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="relative w-full h-screen flex items-center text-cream overflow-hidden">
      <div
        className="
          absolute bottom-4
          left-1/2
          w-full
          md:w-3/4
          h-auto
          z-20
        "
      >
        <div className='transform -translate-x-1/2 space-y-16'>
          <ValentineBanner />
          <motion.a
          className='cursor-pointer pt-12'
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
          href="https://www.viamaestra42.com/"
          target="_blank"
          rel="noreferrer noopener"
          >
            <ViaLink />
          </motion.a>
        </div>
      </div>
      <img src={"https://enoteca-bucket.s3.amazonaws.com/home-hero.webp"} alt="Home Hero" className="absolute inset-0 object-cover w-full h-full" />
      <div className="absolute inset-0 bg-darkBrown opacity-40 z-0"></div> {/* Dark brown overlay */}
      <div className="relative z-10 p-8 md:p-16 max-w-xl flex flex-col items-center md:items-start justify-center h-full ml-0 md:ml-40">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-satoshi tracking-wider mb-4 text-center md:text-left w-full">WELCOME TO</h1>
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-recia font-light mb-4 text-center md:text-left w-full">Via Maestra Enoteca</h2>
        <p className="text-lg md:text-xl lg:text-2xl font-satoshi mb-8 text-center md:text-left w-full">Your Community Wine Bar</p>
        <div className="flex flex-col space-y-4 items-center w-full">
          {/* Join Wine Club Button */}
          <button
            className="w-48 py-3 bg-cream text-maroon font-satoshi rounded-full text-center"
            onClick={openModal}
          >
            Join Our Wine Club
          </button>
          {/* Visit Us Link */}
          <Link
            to="/contact-us"
            className="w-48 py-3 bg-cream text-darkBrown font-satoshi rounded-full text-center"
          >
            Visit Us
          </Link>
        </div>
      </div>
      {/* Include the Modal */}
      <JoinWineClubModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

const ValentineBanner = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="
        bg-maroon 
        text-center 
        py-3
        px-4
        mb-4
      "
    >
      <p className="text-sm md:text-base font-satoshi text-white">
        Valentine’s Day Pizza Specials: Feb 14 & 15 with John Fernandez!
      </p>
      {/* Optional CTA button below */}
      {/* 
      <div className="mt-2">
        <a
          href="/reservations"
          className="
            inline-block
            bg-maroon
            text-cream
            px-4 py-2
            rounded
            hover:bg-olive
            transition
          "
        >
          Reserve Now
        </a>
      </div>
      */}
    </motion.div>
  );
};


export default HomeHero;