import { FC, ReactNode } from "react";
import Footer from "./Footer/Footer";
import { ScrollRestoration } from "react-router-dom";
import Header from "./Header/index";
import { AnimatePresence } from "framer-motion";

// Toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface RootProps {
  children?: ReactNode;
}

const Root: FC<RootProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen text-white overflow-x-hidden">
      <AnimatePresence mode="wait">
        <Header />
        <div className="flex-grow relative">
          {children}
        </div>
        <Footer />
        <ToastContainer 
          position="top-right" 
          className='mt-36'
          autoClose={2500} 
        />
        <ScrollRestoration getKey={(location) => {
          // Handle /menus and /menus/* as the same key
          if (location.pathname.startsWith("/menus")) {
            return "/menus";
          }
          if (location.pathname.startsWith("/locations")) {
            return "/locations";
          }
          return location.pathname;
        }} />
      </AnimatePresence>
    </div>
  );
}

export default Root;